.each-wall {
	& > div {
		padding: 0 120px;
	}

	svg {
		display: block;
		width: 100%;
		max-width: 700px;
		margin: auto;
	}

	.wall-editions {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		.left-edition {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0, -50%);
		}

		.bottom-edition {
			position: absolute;
			left: 50%;
			bottom: 170px;
			transform: translate(-50%, 0);
		}

		.right-edition {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
		}

		.option-content {
			text-align: center;
			padding-bottom: 20px;

			.title {
				font-size: 24px;
				font-weight: bold;
			}
		}
	}
}

@media (max-width: 768px) {
	.each-wall {
		& > div {
			padding: 0 100px;
		}

		.wall-editions {
			.bottom-edition {
				bottom: 80px;
			}

			& > div {
				.option-content {
					padding-bottom: 10px !important;
				}

				.title {
					font-size: 18px !important;
				}

				button {
					font-size: 12px !important;
					padding: 4px 16px !important;
				}
			}
		}
	}
}

@media (max-width: 576px) {
	.each-wall {
		& > div {
			padding: 0 80px;
		}

		.wall-editions {
			.bottom-edition {
				bottom: 50px;
			}

			& > div {
				.option-content {
					padding-bottom: 4px;
				}

				.title {
					font-size: 16px !important;
				}

				button {
					font-size: 10px !important;
					padding: 4px 14px !important;
				}
			}
		}
	}
}