.header {
	display: flex;
	width: 100%;
	height: 75px;
	background-color: #373737;

	& > div {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 50px;
		margin: auto;
		z-index: 1;
	}

	.logo {
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;

		.logo-icon {
			width: 35px;
			height: 35px;
			background-color: #DEB841;
		}
	
		.logo-title {
			font-family: 'Avenir-Black';
			font-weight: bold;
			font-size: 28px;
			color: white;
		}
	}

	.menu-list {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: 'Avenir-Book';
		color: white;
		gap: 10px;

		.list-items {
			display: flex;
			gap: 10px;

			& > div {
				padding: 8px 30px;
				border-radius: 50px;
				cursor: pointer;
				transition: background 0.25s cubic-bezier(0.33, 1, 0.68, 1), color 0.25s cubic-bezier(0.33, 1, 0.68, 1);

				&:hover {
					background: #DEB841;
					color: black;
				}
			}
		}

		.phone-number-button {
			background-color: #DEB841;
			padding: 8px 30px;
    	border-radius: 25px;
			color: black;
			cursor: pointer;
		}
	}

	.dropdown-list {
		display: none;
		align-items: center;
		cursor: pointer;

		span {
			display: block;
			height: 24px;
			position: relative;
			transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
			width: 24px;

			&:after {
				top: 15px;
			}

			&:before {
				top: 7px;
			}

			&:after,
			&:before {
				background: white;
				content: '';
				height: 2px;
				left: 0;
				position: absolute;
				transition: background 0.25s cubic-bezier(0.33, 1, 0.68, 1),top 0.25s cubic-bezier(0.33, 1, 0.68, 1), transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
				width: 100%;
			}

			&:hover {
				&:after,
				&:before {
					background: #DEB841;
				}
			}
		}

		.list-items {
			position: absolute;
			display: flex;
			flex-direction: column;
			top: 0;
			left: 0;
			width: 100%;
			height: 0px;
			font-size: 18px;
			text-align: center;
    	padding-top: 75px;
			gap: 10px;
			color: white;
			background: #373737;
			box-shadow: none;
			transition: box-shadow 0.25s cubic-bezier(0.33, 1, 0.68, 1),height 0.25s cubic-bezier(0.33, 1, 0.68, 1);
			overflow: hidden;
			z-index: -1;

			& > div {
				padding: 10px 0;
				cursor: pointer;
				transition: background 0.25s cubic-bezier(0.33, 1, 0.68, 1), color 0.25s cubic-bezier(0.33, 1, 0.68, 1);

				&:hover {
					background-color: #DEB841;
					color: black;
				}
			}

			& > div:first-of-type {
				margin-top: 10px;
			}

			& > div:last-of-type {
				margin-bottom: 10px;
			}
		}
	}
	
	&.is-open {
		.dropdown-list {
			span {
				transform: rotate(180deg) scale(0.9);
			}
	
			span:after {
				transform: rotate(-45deg);
			}
	
			span:before {
				transform: rotate(45deg);
			}
	
			span:after,
			span:before {
				top: 50%;
			}

			.list-items {
				height: 313px;
				box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
			}
		}
	}
}

@media (max-width: 992px) {
	.header {
		& > div {
			padding: 0 30px;
		}
	}

	.menu-list {
		gap: 10px !important;

		.list-items {
			gap: 10px !important;

			& > div {
				padding: 6px 18px !important;
			}
		}

		.phone-number-button {
			padding: 6px 18px !important;
		}
	}
}

@media (max-width: 768px) {
	.header {
		.menu-list {
			display: none;
		}

		.dropdown-list {
			display: flex;
		}
	}
}

@media (max-width: 576px) {
	:root {
		--headline-700-size: 24px;
	}
}