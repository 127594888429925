.setting-modal {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	padding: 20px;
	top: 0;
	left: 0;
	z-index: 1000;
	transition: background-color .15s linear;

	&.fade {
		background-color: rgba($color: #000000, $alpha: 0);
		visibility: hidden;
	}

	&.show {
		background-color: rgba($color: #000000, $alpha: 0.5);
		visibility: visible;
	}

	.modal-content {
		width: 100%;
		max-width: 500px;
		height: 620px;
		padding: 50px 0;
		text-align: center;
		border: none;
		gap: 20px;
		overflow-x: hidden;

		.title {
			font-family: "Avenir-Black";
			font-size: var(--headline-700-size);
			font-weight: bold;
		}

		.step-title {
			padding-bottom: 20px;
			font-size: var(--headline-500-size);
    	font-weight: bold;

			.arrow {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 25px;
				height: 25px;
				background-color: transparent;
				border-radius: 100%;
				transition: background 0.25s cubic-bezier(0.33, 1, 0.68, 1);

				&:hover {
					background-color: #BFBDC1;
				}

				i {
					cursor: pointer;
				}

				.bi-chevron-left {
					padding-right: 2px;
				}

				.bi-chevron-right {
					padding-left: 2px;
				}
			}
		}

		.options {
			display: flex;
	    transition: transform 0.25s linear;

			.option-content {
				flex: 1;
				overflow: hidden;

				& > div {
					padding: 10px 50px;
				}

				.cards {
					gap: 15px;
		
					.setting-card {
						display: flex;
						align-items: center;
						gap: 30px;
						padding: 15px 25px;
						box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
						font-size: var(--body-500-size);
						cursor: pointer;
						border: 1px solid transparent;
						transition: border-color 0.25s cubic-bezier(0.33, 1, 0.68, 1);
		
						&.is-active,
						&:hover {
							border-color: #DEB841;
						}
			
						& > div {
							display: flex;
							flex-direction: column;
							text-align: left;
							gap: 4px;
		
							& > div:first-of-type {
								font-weight: bold;
								font-family: 'Avenir-Black';
							}
		
							.measurements-input,
							& > div:last-of-type {
								display: flex;
								align-items: center;
								gap: 10px;

								& > div {
									display: flex;
									align-items: center;
									gap: 10px;
								}
		
								input,
								select {
									width: 48px;
									padding: 5px;
									text-align: center;
									border: 0.5px solid #BFBDC1;
									border-radius: 5px;
								}
		
								select {
									text-align: left;
								}
							}
						}
		
						input[type = radio] {
							appearance: none;
							height: 20px;
							width: 20px;
							border-radius: 50%;
							background-color: #D9D9D9;
							border: 1px solid #D9D9D9;
							box-shadow: 0 0 0 1px #9B9B9B;
							pointer-events: none;
		
							&:checked {
								border: 0.2rem solid #D9D9D9;
								box-shadow: 0 0 0 1px #9B9B9B;
								background-color: #DEB841;
							}
						}
					}

					&.wall-feature {
						select {
							width: 100% !important;
							padding: 5px;
							font-size: 14px;
							text-align: center;
							border: 0.5px solid #BFBDC1;
							border-radius: 5px;
						}
					}
				}
			}
		}

		button {
			width: fit-content;
			margin: auto;
			margin-bottom: 0;
		}
	}
}

@media (max-width: 505px) {
	.measurements-input {
		flex-direction: column;
		align-items: flex-start !important;
	}
}